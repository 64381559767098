
import { defineComponent, toRefs, Ref } from 'vue';
import {mapState} from 'vuex';

import {useForumDetail, useThreadsList} from '@/compositions/bbs';

import BreadCrumbs from '@/components/widgets/bread-crumbs.vue';
import ThreadList from '@/components/bbs/thread-list.vue';
import ThreadCreateModal from '@/components/modals/bbs-thread-create.vue';

export default defineComponent({

    components: {
        BreadCrumbs,
        ThreadList,
        ThreadCreateModal
    },

    props: {
        id: Number,
        page: Number
    },

    setup: (props) => {

        const {id} = toRefs(props);
        const forumDetailSetup = useForumDetail(id as Ref<number>);

        return {
            ...forumDetailSetup
        };
    },

    data: function () {
        return {
            showCreateModal: false
        };
    },

    methods: {

        createThread: function () {
            this.showCreateModal = true;
        },

        onCreateSuccess: function () {
            window.location.reload();
        }
    }
});
