
import {defineComponent} from 'vue';
import {createThreadApi} from "@/api/bbs";

import Upload from '../widgets/upload.vue';

export default defineComponent({

    components: {
        Upload
    },

    props: {
        fid: {
            type: Number,
            required: true
        }
    },

    data: function () {
        return {
            subject: '',
            message: '',
            images: []
        };
    },

    methods: {

        close: function () {
            this.$emit('close');
        },

        submit: async function () {

            let res = await createThreadApi({
                fid: this.fid,
                subject: this.subject,
                message: this.message,
                images: this.images.length ? this.images : ''
            });

            if (res) {
                this.close();
                this.$emit('success');
            }
        },

        uploadSuccess: function (attachment) {
            this.images.push(attachment.url);
        },

        removeImage: function (index) {
            this.images.splice(index, 1);
        }
    }
});
