
import {defineComponent, toRefs, Ref} from 'vue';

import {useThreadsList} from '@/compositions/bbs';
import Pagination from '@/components/widgets/pagination.vue';
import {dateFormat, timeSince} from '@/utils/datetime';

export default defineComponent({

    components: {
        Pagination
    },

    props: {
        fid: Number,
        type: {
            type: String,
            default: 'forum'
        },
        page: Number
    },

    setup: (props) => {

        const {fid, type, page} = toRefs(props);
        const threadsListSetup = useThreadsList(type as Ref<string>, page as Ref<number>, fid as Ref<number>);

        return {
            ...threadsListSetup
        };
    },

    data: function () {

        return {

        };
    },

    methods: {
        dateFormat,
        timeSince
    }
});
