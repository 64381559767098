<template>
    <div class="channel-topic-list">
        <ul class="clearfix">
            <li class="first">
                <div class="col col1">帖子标题</div>
                <div class="col col2">作者/发布时间</div>
                <div class="col col3">回复数</div>
                <div class="col col4">最后回复时间</div>
            </li>

            <li v-for="thread in threads" :key="thread.id">
                <div class="col col1">
                    <span class="qz "></span>
                    <router-link class="col title-text"
                            :to="{name: 'bbs-thread', query: {id: thread.id}}">
                        {{thread.subject}}
                    </router-link>
                    <div class="img-container" v-if="thread.icon">
                        <a :to="{name: 'bbs-thread', query: {id: thread.id}}">
                            <img :src="thread.icon" />
                        </a>
                    </div>
                </div>
                <div class="col col2">
                    <span class="athor-name">{{thread.author_name}}</span>
                    <span class="publish-time">{{dateFormat(thread.add_time)}}</span>
                </div>
                <div class="col col3">
                    <span class="comment-count">{{thread.replies}}</span>
                </div>
                <div class="col col4">
                    <span class="last-reply-time">{{thread.lastpost ? timeSince(thread.lastpost) : '-'}}</span>
                </div>
            </li>

        </ul>

        <div class="list-page-footer clearfix">
            <div>
                <pagination :total="total"
                        :page="page" />
            </div>
        </div>

    </div>
</template>

<script lang="ts">
import {defineComponent, toRefs, Ref} from 'vue';

import {useThreadsList} from '@/compositions/bbs';
import Pagination from '@/components/widgets/pagination.vue';
import {dateFormat, timeSince} from '@/utils/datetime';

export default defineComponent({

    components: {
        Pagination
    },

    props: {
        fid: Number,
        type: {
            type: String,
            default: 'forum'
        },
        page: Number
    },

    setup: (props) => {

        const {fid, type, page} = toRefs(props);
        const threadsListSetup = useThreadsList(type as Ref<string>, page as Ref<number>, fid as Ref<number>);

        return {
            ...threadsListSetup
        };
    },

    data: function () {

        return {

        };
    },

    methods: {
        dateFormat,
        timeSince
    }
});
</script>