import request from '@/utils/request';

export function getForumList () {

    return request.get('v2/bbs/forums', {}, {noAuth: true});
}

export function getForumDetail (id) {

    return request.get('v2/bbs/forums/detail/' + id, {}, {noAuth: true});
}

export function getThreadsList (data) {

    return request.get('v2/bbs/threads', data, {noAuth: true});
}

export function getThreadsDetail (id) {

    return request.get('v2/bbs/threads/detail/' + id, {}, {noAuth: true});
}

export function createThreadApi (data) {

    return request.post('v2/bbs/threads', data);
}

export function getPostsList (data) {

    return request.get('v2/bbs/posts', data, {noAuth: true});
}

export function createPostApi (data) {

    return request.post('v2/bbs/posts', data);
}