import { ref, onMounted, onUnmounted, computed, watch, Ref } from 'vue';
import {
    getForumList, getForumDetail,
    getThreadsList, getThreadsDetail,
    getPostsList, createPostApi
} from '@/api/bbs';

export function useForumList () {
    const forums = ref([]);

    const getForums = async () => {
        const res = await getForumList();

        forums.value = res.data;
    };

    onMounted(getForums);

    return {
        forums
    };
}

export function useForumDetail (id: Ref<number>) {

    const forum = ref({});

    const getDetail = async () => {
        const res = await getForumDetail(id.value);

        forum.value = res.data;
    };

    onMounted(getDetail);

    return {
        forum
    };
}

export function useThreadsList (type: Ref<string>, page: Ref<number>, fid?: Ref<number>) {

    const threads = ref([]);
    const total = ref(0);

    const getList = async () => {
        const res = await getThreadsList({
            type: type.value,
            fid: fid ? fid.value : '',
            page: page.value || 1
        });

        threads.value = res.data.list;
        total.value = res.data.count;
    };

    onMounted(getList);
    watch([type, page, fid], getList);

    return {
        threads,
        total
    };
}

export function useThreadsDetail (id: Ref<number>) {

    const thread = ref({author: {}});

    const getDetail = async () => {
        const res = await getThreadsDetail(id.value);

        thread.value = res.data;
    };

    onMounted(getDetail);
    watch(id, getDetail);

    return {
        thread
    };
}

function linkReplies (posts) {

    let map = {};

    posts && posts.forEach((post) => {
        map[post.id] = post;

        if (post.replies) {
            post.replies.forEach((reply) => {
                map[reply.id] = reply;
                if (reply.rid && reply.rid !== reply.pid) {
                    reply.reply_name = map[reply.rid] ? map[reply.rid].author_name : '';
                }
            });
        }
    });
}

export function usePostsList (tid: Ref<number>, page: Ref<number>) {

    const posts = ref([]);
    const total = ref(0);

    const getList = async () => {
        const res = await getPostsList({
            tid: tid ? tid.value : '',
            page: page.value || 1
        });

        linkReplies(res.data.list);

        posts.value = res.data.list;
        total.value = res.data.count;
    };

    onMounted(getList);
    watch([tid, page], getList);

    return {
        posts,
        total
    };
}