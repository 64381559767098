
import {defineComponent} from 'vue';

import {upload} from '@/api/public';

export default defineComponent({

    props: {
        multiple: {
            type: Boolean,
            default: false
        },
        accept: {
            type: String,
            default: 'image/*'
        },
        modelValue: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },

    computed: {
        showUpload: function () {
            if (!this.modelValue.length) {
                return true;
            }
            else {
                return this.multiple;
            }
        }
    },

    methods: {
        fileSelect: async function (e) {

            let files = e.target.files;

            if (files && files.length) {
                files = [...files];

                this.$refs.fileForm.reset();

                for (let i = 0; i < files.length; i++) {
                    let file = files[i];

                    if (this.accept) {
                        if (this.accept === 'image/*' && file.type.slice(0, 5) !== 'image') {
                            this.$emit('on-error', {
                                message: '不支持的文件类型，只支持上传图片'
                            });
                            continue;
                        }
                    }

                    let res = await upload(file);

                    this.$emit('on-success', res.data);
                    this.$emit('update:modelValue', [...this.modelValue, res.data.url]);
                }
            }
        },

        remove: function (index) {
            let value = [...this.modelValue];
            value.splice(index, 1);
            this.$emit('update:modelValue', value);
        }
    }
});
