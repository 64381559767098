<template>
    <div class="com-dialog-template">
        <div class="mengban"></div>
        <div class="table">
            <div class="table-cell">
                <div class="dialog-box">

                    <a class="close" @click="close">×</a>

                    <div class="dialog-content">
                        <!-- <p class="dialog-login-title">新话题</p> -->
                        <div class="dialog-login-box">
                            <div class="com-login-share">
                                <div class="com-login verify-form rel">
                                    <h1 class="f18">新话题</h1>

                                    <form class="mt20" @submit.prevent="submit">
                                        <div class="hang">
                                            <input type="text" name="subject" placeholder="请输入主题"  v-model="subject">
                                        </div>

                                        <div class="hang">
                                            <textarea v-model="message" placeholder="请输入内容"></textarea>
                                        </div>

                                        <div class="hang">

                                            <div class="attachment" v-for="(image, index) in images" :key="index">
                                                <img :src="image" alt="" />

                                                <a class="remove" @click="removeImage(index)">×</a>
                                            </div>

                                            <upload v-if="images.length < 5" @on-success="uploadSuccess" />
                                        </div>

                                        <div class="error cp-pumpkin mt5 animated  opa0">
                                            <p></p>
                                        </div>

                                        <button type="submit" class="submit btn-flat mt5 f16 cf">发 布</button>
                                    </form>

                                    <div class="footer cl mt10 hide">
                                        <!-- <a class="cp-pumpkin dec-line fr pointer">?</a> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {createThreadApi} from "@/api/bbs";

import Upload from '../widgets/upload.vue';

export default defineComponent({

    components: {
        Upload
    },

    props: {
        fid: {
            type: Number,
            required: true
        }
    },

    data: function () {
        return {
            subject: '',
            message: '',
            images: []
        };
    },

    methods: {

        close: function () {
            this.$emit('close');
        },

        submit: async function () {

            let res = await createThreadApi({
                fid: this.fid,
                subject: this.subject,
                message: this.message,
                images: this.images.length ? this.images : ''
            });

            if (res) {
                this.close();
                this.$emit('success');
            }
        },

        uploadSuccess: function (attachment) {
            this.images.push(attachment.url);
        },

        removeImage: function (index) {
            this.images.splice(index, 1);
        }
    }
});
</script>

<style scoped>
.verify-form {
    width: 800px;
}

.hang textarea {
    width: 100%;
    min-height: 200px;
}

.dialog-box {
    height: 80%;
}

.dialog-content {
    height: 100%;
    overflow-y: auto;
}

.attachment {
    display: inline-block;
    width: 60px;
    height: 60px;
    margin-right: 20px;
    position: relative;

    img {
        width: 100%;
        height: 100%;
    }

    .remove {
        position: absolute;
        right: -10px;
        top: 0;
        font-size: 20px;
        display: none;
    }

    &:hover .remove {
        display: block;
    }
}
</style>
