<template>

    <template v-if="modelValue">
        <div class="attachment" v-for="(image, index) in modelValue" :key="index">
            <img :src="image" />

            <a class="remove" @click="remove(index)">×</a>
        </div>
    </template>

    <form v-if="showUpload" class="upload-form" ref="fileForm" onsubmit="return false">
        <div class="upload-button">
            <input type="file" :accept="accept" :multiple="multiple" @change="fileSelect" />
            上传
        </div>
    </form>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

import {upload} from '@/api/public';

export default defineComponent({

    props: {
        multiple: {
            type: Boolean,
            default: false
        },
        accept: {
            type: String,
            default: 'image/*'
        },
        modelValue: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },

    computed: {
        showUpload: function () {
            if (!this.modelValue.length) {
                return true;
            }
            else {
                return this.multiple;
            }
        }
    },

    methods: {
        fileSelect: async function (e) {

            let files = e.target.files;

            if (files && files.length) {
                files = [...files];

                this.$refs.fileForm.reset();

                for (let i = 0; i < files.length; i++) {
                    let file = files[i];

                    if (this.accept) {
                        if (this.accept === 'image/*' && file.type.slice(0, 5) !== 'image') {
                            this.$emit('on-error', {
                                message: '不支持的文件类型，只支持上传图片'
                            });
                            continue;
                        }
                    }

                    let res = await upload(file);

                    this.$emit('on-success', res.data);
                    this.$emit('update:modelValue', [...this.modelValue, res.data.url]);
                }
            }
        },

        remove: function (index) {
            let value = [...this.modelValue];
            value.splice(index, 1);
            this.$emit('update:modelValue', value);
        }
    }
});
</script>

<style scoped lang="scss">
.upload-form {
    display: inline-block;
    vertical-align: text-bottom;
}

.upload-button {
    position: relative;
    width: 60px;
    height: 60px;
    border: solid 1px #eee;
    display: flex;
    align-items: center;
    justify-content: center;

    input[type=file] {
        display: block;
        width: 100%;
        height: 100%;
        appearance: none;
        opacity: 0;
        position: absolute;
        left: 0; top: 0;
    }
}

.attachment {
    display: inline-block;
    width: 60px;
    height: 60px;
    margin-right: 20px;
    position: relative;

    img {
        width: 100%;
        height: 100%;
    }

    .remove {
        position: absolute;
        right: -10px;
        top: 0;
        font-size: 20px;
        display: none;
    }

    &:hover .remove {
        display: block;
    }
}
</style>