<template>
    <div class="common-paging clearfix" v-if="totalPage > 1">
        <ul>
            <li class="pre" :class="{'disabled': page === 1}" @click="prev">&nbsp;</li>
            <li v-for="n in totalPage" :key="n"
                    :class="{'active': n === page}">
                <a @click="toPage(n)">{{n}}</a>
            </li>
            <li class="next" :class="{'disabled': page === totalPage}" @click="next"><a>&nbsp;</a></li>
        </ul>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

import {mergeUrlParams} from '@/utils/url';

export default defineComponent({

    props: {
        total: {
            type: Number,
            required: true
        },
        to: {
            type: Object
        },
        page: {
            type: Number,
            default: 1
        },
        pageSize: {
            type: Number,
            default: 20
        }
    },

    computed: {
        totalPage: function () {
            return Math.ceil(this.total / this.pageSize);
        }
    },

    methods: {
        toPage: function (n) {

            let url;

            if (this.to) {
                url = mergeUrlParams(this.to.path, {
                    ...this.to.query,
                    page: n
                });
            }
            else {
                url = mergeUrlParams(this.$route.path, {
                    ...this.$route.query,
                    page: n
                });
            }

            this.$router.push(url);
        },

        prev: function () {
            this.toPage(this.page - 1);
        },

        next: function () {
            this.toPage(this.page + 1);
        }
    }
});
</script>